import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  contentHolder: {
    display: 'none',
    flexGrow: 1,
    marginLeft: 24,

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
    },
  },
  logoContainer: {
    display: 'flex',

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  navContianer: {
    overflowY: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px',

    [BREAKPOINTS.TABLET]: {
      padding: '0 5px',
    },
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  feedbackContainer: {
    height: 47,

    [BREAKPOINTS.TABLET]: {
      margin: '10px 0',
    },
  },
  feedbackHolder: {
    overflow: 'hidden',
    padding: '0 20px',

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  searchButton: {
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  logo: {
    [BREAKPOINTS.TABLET]: {
      display: 'none',
    },
  },
  logoSmall: {
    marginLeft: 4,

    [BREAKPOINTS.DESKTOP]: {
      display: 'none',
    },
  },
  menuBurgerContainer: {
    display: 'none',
    marginLeft: 18,

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
    },
  },
  unfoldMenu: {
    display: 'flex',
    position: 'absolute',
    cursor: 'pointer',
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',

    [BREAKPOINTS.DESKTOP]: {
      display: 'none',
    },
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
    background: COLORS.WHITE,
    width: '100%',
    height: 56,
    color: COLORS.BRAND.PRIMARY,
  },
  collapseMenu: {
    width: 56,
    top: 12,
    color: COLORS.TEXT.SECONDARY,
    background: 'transparent',
  },
  shown: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
  borderBottomShown: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
  },
  smallLogo: {
    fill: COLORS.BRAND.PRIMARY,
    width: 24,
  },
  currentUserMenu: {
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
});
