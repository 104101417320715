import httpClient from '@shared/api/httpClient';
import { apiUrl } from '@configs/environment';
import { FetchedMyTeamEvalData } from './types';
import { MyTeamEvalData } from '../interfaces/MyTeamEvalData';
import { evaluationRescheduleHistoryMapFunc } from './../helpers/mapFunctions/evaluationRescheduleHistoryMapFunc';
import getEvaluationPermissions from './helpers/getEvaluationPermissions';
import { clientReviewerParser, peerReviewerParser } from './helpers/responseParsers';

export const fetchMyTeamEvalData = (): Promise<MyTeamEvalData[]> =>
  httpClient.get<FetchedMyTeamEvalData>(`${apiUrl}/evaluations/my-reporters/`).then((resp) => {
    return resp.data.data.map(
      ({
        imageUrl,
        email,
        full_name,
        people_partner,
        status,
        evaluation_id,
        evaluation_date,
        position,
        id,
        is_submitted_by_evaluator,
        is_submitted_by_evaluatee,
        google_calendar_event,
        primary_reports_to,
        country,
        city,
        office_country,
        peer_reviewers,
        client_reviewers,
        is_primary_reporter,
        evaluation_reschedulings,
        department_name,
        type_of_interaction,
        meta: { permissions },
        has_feedbacks,
        is_extendable,
        eop_resolved_at,
        eop_extended_to,
        eop_status,
      }) => ({
        email,
        name: full_name,
        userId: id,
        evaluationId: evaluation_id,
        imageUrl,
        peoplePartner: {
          id: people_partner?.id,
          email: people_partner?.email,
          name: people_partner?.full_name,
          imageUrl: people_partner?.imageUrl,
        },
        position,
        status,
        evaluationDate: evaluation_date,
        isSubmittedByEvaluator: is_submitted_by_evaluator,
        isSubmittedByEvaluatee: is_submitted_by_evaluatee,
        googleCalendarEvent: google_calendar_event
          ? {
              ...google_calendar_event,
              attendees: google_calendar_event.attendees.map((attendee) => {
                return {
                  name: attendee.full_name,
                  email: attendee.email,
                  id: attendee.id,
                  imageUrl: attendee.imageUrl,
                  position: attendee.position,
                  city: attendee.city,
                  country: attendee.country,
                };
              }),
            }
          : null,
        primaryReportsTo: {
          id: primary_reports_to.id,
          email: primary_reports_to.email,
          name: primary_reports_to.full_name,
          imageUrl: primary_reports_to.imageUrl,
        },
        country: country,
        city: city,
        officeCountry: office_country,
        peerReviews: peer_reviewers?.map(peerReviewerParser) || null,
        clientReviews: client_reviewers?.map(clientReviewerParser) || null,
        isPrimaryReporter: is_primary_reporter,
        permissions: getEvaluationPermissions(permissions).evaluation,
        evaluationRescheduleHistory: evaluationRescheduleHistoryMapFunc(evaluation_reschedulings),
        project: department_name,
        typeOfInteraction: type_of_interaction,
        hasFeedbacks: has_feedbacks,
        isExtendable: is_extendable,
        eopResolvedAt: eop_resolved_at,
        eopStatus: eop_status,
        eopExtendedTo: eop_extended_to,
      })
    );
  });
