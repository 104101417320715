import { apiUrl } from '@configs/environment';
import httpClient from '@shared/api/httpClient';
import { UserEvalPageDataNoPermissions } from '../interfaces/UserEvalPageData';
import { UserEvalResponseData } from './types';
import getEvaluationPermissions from './helpers/getEvaluationPermissions';

export const fetchUserEvalData = (userId: string): Promise<UserEvalPageDataNoPermissions> =>
  httpClient.get<UserEvalResponseData>(`${apiUrl}/evaluations`, { params: { user_id: userId } }).then((resp) => {
    const history = resp.data.data.history.map((r) => {
      const {
        evaluation_date,
        position,
        retention_risk,
        people_partner,
        manager,
        department,
        primary_reports_to,
        secondary_reports_to,
        evaluation_id,
        type_of_interaction,
        status,
        is_submitted_by_evaluator,
        is_submitted_by_evaluatee,
        google_calendar_event,
        is_not_rocky,
        meta: { permissions },
        has_feedbacks,
        is_extendable,
        eop_resolved_at,
        eop_extended_to,
        eop_status,
      } = r;
      return {
        hasFeedbacks: has_feedbacks,
        permissions: getEvaluationPermissions(permissions).evaluation,
        evalType: type_of_interaction,
        date: evaluation_date,
        position,
        retentionRisk: retention_risk,
        peoplePartner: people_partner
          ? {
              ...people_partner,
              name: people_partner?.full_name,
              imageUrl: people_partner?.imageUrl,
            }
          : undefined,
        manager: manager
          ? {
              ...manager,
              name: manager?.full_name,
            }
          : null,
        department,
        primaryReportsTo: primary_reports_to
          ? {
              ...primary_reports_to,
              name: primary_reports_to.full_name,
              imageUrl: primary_reports_to.imageUrl,
            }
          : undefined,
        secondaryReportsTo: secondary_reports_to
          ? {
              ...secondary_reports_to,
              name: secondary_reports_to.full_name,
              imageUrl: secondary_reports_to.imageUrl,
            }
          : undefined,
        team: '', // TODO: Implement on backend and add here
        evaluationId: evaluation_id,
        status,
        submittedByEvaluator: is_submitted_by_evaluator,
        submittedByEvaluatee: is_submitted_by_evaluatee,
        googleCalendarEvent: google_calendar_event
          ? {
              ...google_calendar_event,
              attendees: google_calendar_event.attendees.map((attendee) => {
                return {
                  name: attendee.full_name,
                  email: attendee.email,
                  id: attendee.id,
                  imageUrl: attendee.imageUrl,
                  position: attendee.position,
                  city: attendee.city,
                  country: attendee.country,
                };
              }),
            }
          : null,
        isNonRocky: is_not_rocky,
        isExtendable: is_extendable,
        eopResovledAt: eop_resolved_at,
        eopExtendedTo: eop_extended_to,
        eopStatus: eop_status,
      };
    });
    const {
      id,
      full_name,
      email,
      position,
      imageUrl,
      client_partner,
      people_partner,
      manager,
      primary_reports_to,
      secondary_reports_to,
      date_of_joining,
    } = resp.data.data.user;
    return {
      history,
      user: {
        id,
        name: full_name,
        email,
        position,
        imageUrl,
        clientPartner: client_partner
          ? {
              ...client_partner,
              name: client_partner?.full_name,
              imageUrl: client_partner?.imageUrl,
            }
          : undefined,
        peoplePartner: people_partner
          ? {
              ...people_partner,
              name: people_partner?.full_name,
              imageUrl: people_partner?.imageUrl,
            }
          : undefined,
        manager: manager
          ? {
              ...manager,
              name: manager?.full_name,
              imageUrl: manager?.imageUrl,
            }
          : undefined,
        primaryReportsTo: primary_reports_to
          ? {
              ...primary_reports_to,
              name: primary_reports_to.full_name,
              imageUrl: primary_reports_to.imageUrl,
            }
          : undefined,
        secondaryReportsTo: secondary_reports_to
          ? {
              ...secondary_reports_to,
              name: secondary_reports_to.full_name,
              imageUrl: secondary_reports_to.imageUrl,
            }
          : undefined,
        employmentDate: date_of_joining,
      },
    };
  });
