import { UserInfo } from '@shared/interfaces/UserInfo';
import { User, UserMainData } from '@shared/interfaces/user';

export const mapUserToSharedUser = (user: User | UserMainData): UserInfo => ({
  name: user.name,
  id: user.id,
  email: user.email,
  position: user.position,
  primaryReportsTo: user.primaryReportsTo,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  engineeringManager: (user as any)?.engineeringManager,
  country: user.country,
  city: user.city,
  imageUrl: user.imageUrl,
  departments: user.departments,
  primaryDepartment: user.primaryDepartment,
});
