import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { RaiseFlagData } from '@modules/HappinessModule/interfaces/RaiseFlagData';

export const mapNewProjectAssignmentDataToPayload = (
  data: FlagFields['newProjectAssignment']
): RaiseFlagData['newProjectAssignment'] => {
  if (!data) {
    return null;
  }
  const { nearestEval, project, ...restPayloadData } = data;
  return {
    ...restPayloadData,
    nextEvaluationId: nearestEval.id,
    projectId: project.id,
    addHRSupport: data.addHRSupport ?? false,
  };
};
