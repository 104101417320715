import React, { FC, memo, useEffect } from 'react';
import { DefaultLayout } from '@shared/components/DefaultLayout';
import { Outlet } from 'react-router';
import { Role } from '@shared/enums/role';
import { useLocation } from 'react-router-dom';
import { RocketLoader } from '../RocketLoader';
import { useStyles } from './AuthProtectedRoute.styles';
import { useAuth } from '@shared/hooks/useAuth';
import { AccessDeniedView } from '../DataErrorView/components/AccessDeniedView';
import * as Sentry from '@sentry/react';
import { ErrorView } from '@modules/App/pages/ErrorView';
import { TERMS_OF_USE_VERSION } from '../TermsOfUse/consntants';
import { TermsOfUseOverlay } from '../TermsOfUse/TermsOfUseOverlay';
import { useDispatch } from 'react-redux';
import { acceptTermsOfUse } from '@modules/App/redux/user/actions';
import { RockyComponentsProvider } from '@product-studio/rocky-components';
import { getAccessToken } from '@shared/helpers/auth';
import { Enviroment } from '@shared/enums/EnvironmentNames';

interface Props {
  allowedRoles?: Role[];
  checkIfUserHasReporters?: boolean;
  isAvailableForManagers?: boolean;
  isCheckHappinessAvailabilityInCurrentUser?: boolean;
}

const AuthProtectedRouteComponent: FC<Props> = ({
  allowedRoles: roles,
  checkIfUserHasReporters,
  isAvailableForManagers = false,
  isCheckHappinessAvailabilityInCurrentUser = false,
}) => {
  const styles = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const acceptTou = () => dispatch(acceptTermsOfUse(TERMS_OF_USE_VERSION));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { currentUser } = useAuth();

  const isTouAccepted = currentUser?.latestTermsOfUseVersionAccepted === TERMS_OF_USE_VERSION;
  const isAllowed = isCheckHappinessAvailabilityInCurrentUser
    ? currentUser?.hasAvailableHappinessExceptOwn
    : !roles ||
      (currentUser && currentUser.rockyRoles.some((r) => roles.includes(r))) ||
      (checkIfUserHasReporters && currentUser?.hasReporters);

  return currentUser ? (
    isTouAccepted ? (
      <RockyComponentsProvider
        token={getAccessToken() ?? ''}
        env={
          process.env.REACT_APP_ENV === Enviroment.Production
            ? 'prod'
            : process.env.REACT_APP_ENV === Enviroment.Develop || process.env.REACT_APP_ENV === Enviroment.Staging
            ? 'dev'
            : 'local'
        }
      >
        <DefaultLayout currentUser={currentUser}>
          {!isAllowed || (isAvailableForManagers && !currentUser.hasReporters) ? (
            <div className={styles.container}>
              <AccessDeniedView />
            </div>
          ) : (
            <Sentry.ErrorBoundary fallback={<ErrorView component />}>
              <Outlet></Outlet>
            </Sentry.ErrorBoundary>
          )}
        </DefaultLayout>
      </RockyComponentsProvider>
    ) : (
      <TermsOfUseOverlay onTermsAccept={acceptTou}></TermsOfUseOverlay>
    )
  ) : (
    <div className={styles.loaderHolder}>
      <RocketLoader></RocketLoader>
      <h3 className={styles.loadingText}>Launching space mission</h3>
    </div>
  );
};

export const AuthProtectedRoute = memo(AuthProtectedRouteComponent);
