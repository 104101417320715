import { FC, memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useStyles } from './MyTeamEvalReportTable.styles';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { paths } from '@shared/enums/paths';
import { Table } from '@shared/components/Table';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { setSelectedEvalsAction } from '@modules/EvaluationModule/pages/MyTeamEvalPage/redux/actions';
import { ActionButtons } from '../../shared/ActionButtons';
import { useSelectEvals } from '../../hooks/useSelectEvals';
import { EvalStatus } from '../../shared/EvalStatus/EvalStatus';
import { UsersDropdown } from './componets/UsersDropdown/UsersDropdown';
import classnames from 'classnames';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import format from 'date-fns/format';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { IconButton, Stack } from '@mui/material';
import { useModalState } from '@shared/hooks/useModalState';
import { EvaluationRescheduleHistoryData } from '@modules/EvaluationModule/interfaces/EvaluationRescheduleHistory';
import { RescheduledEvalInfoModal } from '../../shared/RescheduledEvalInfoModal';
import { EvaluationType } from '@modules/EvaluationModule/enums/EvaluationType';
interface Props {
  data: MyTeamEvalData[] | null;
  sortInfo: SortInfo;
  isDataLoading: boolean;
  onSortClick: (sortInfo: SortInfo) => void;
}

const MyTeamEvalReportTableComponent: FC<Props> = ({ data, sortInfo, isDataLoading, onSortClick }) => {
  const styles = useStyles();
  const location = useLocation();
  const { handleSendTheFormButtonClick, handleFailEopButtonClick, handleClickOnRow, startingEvalId, failingEopId } =
    useSelectEvals(setSelectedEvalsAction);

  const tableHeaders = [
    { title: 'Member', fieldName: 'name' },
    { title: 'Month', fieldName: 'evaluationDate' },
    { title: '' },
    { title: '' },
    { title: '' },
    { title: '' },
  ];
  const { isModalOpen, closeModal, openModal } = useModalState();
  const [selectedLastRescheduleInfo, setSelectedLastRescheduleInfo] = useState<{
    info: EvaluationRescheduleHistoryData;
    isEop: boolean;
  } | null>(null);

  const tableContent =
    data &&
    data.map((evaluation) => {
      const {
        name,
        imageUrl,
        email,
        position,
        project,
        userId,
        status,
        evaluationId,
        evaluationDate,
        country,
        city,
        peerReviews,
        clientReviews,
        isPrimaryReporter,
        typeOfInteraction,
        permissions: { isUpdatable },
        evaluationRescheduleHistory,
        eopResolvedAt,
      } = evaluation;
      const isProcessingEval = startingEvalId !== evaluationId && failingEopId !== evaluationId;

      const lastRescheduleInfo = [...evaluationRescheduleHistory].sort((a, b) =>
        a.modificationDate < b.modificationDate ? 1 : -1
      )[0];

      return (
        <tr
          key={email}
          onClick={() => isPrimaryReporter && handleClickOnRow(status, userId, evaluationId)}
          className={classnames(styles.tableDataRow, {
            [styles.starting]: startingEvalId === evaluationId,
            [styles.noClickable]: !isPrimaryReporter,
            [styles.canceling]: failingEopId === evaluationId,
          })}
        >
          <td onClick={(e) => e.stopPropagation()}>
            <div className={styles.memberBlock}>
              <Link
                className={styles.personBlock}
                to={`${paths.users}/${userId}/${paths.evaluations}`}
                state={{ path: location.pathname, name: 'team evaluations' }}
              >
                <UserReporterBlock
                  isShowProject
                  isDetailInfo
                  user={{ id: userId, email, name, position, project, imageUrl, country, city }}
                />
              </Link>
            </div>
          </td>
          <td>
            <Stack flexDirection="row" columnGap="4px" alignItems="center">
              <div>{format(new Date(evaluationDate), 'LLLL')}</div>
              {lastRescheduleInfo && (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    openModal();
                    setSelectedLastRescheduleInfo({
                      info: lastRescheduleInfo,
                      isEop: typeOfInteraction === EvaluationType.EndOfProbation,
                    });
                  }}
                >
                  <CommentOutlinedIcon color="disabled" fontSize="small" />
                </IconButton>
              )}
            </Stack>
          </td>
          <td>
            {evaluationDate && status && (
              <EvalStatus
                status={status}
                evaluationDate={evaluationDate}
                eopResolvedAt={eopResolvedAt}
                typeOfInteraction={typeOfInteraction}
                eopExtendedTo={evaluation.eopExtendedTo}
                eopStatus={evaluation.eopStatus}
              />
            )}
          </td>
          <td colSpan={isUpdatable ? 1 : 3}>
            {isUpdatable ? (
              <UsersDropdown
                evaluationInfo={evaluation}
                name="Clients"
                linkPath={`${paths.users}/${userId}/${paths.evaluations}/${evaluationId}/${paths.settings}`}
                userReviews={clientReviews}
              />
            ) : (
              <span className={styles.secondaryReporterLabel}>Secondary reporter</span>
            )}
          </td>
          <td>
            {isUpdatable && (
              <UsersDropdown
                evaluationInfo={evaluation}
                name="Peers"
                linkPath={`${paths.users}/${userId}/${paths.evaluations}/${evaluationId}/${paths.settings}`}
                userReviews={peerReviews}
              />
            )}
          </td>
          {status && (
            <td>
              {isProcessingEval ? (
                isUpdatable && (
                  <ActionButtons
                    evaluation={evaluation}
                    handleSendTheFormButtonClick={handleSendTheFormButtonClick}
                    handleFailEopButtonClick={handleFailEopButtonClick}
                  />
                )
              ) : (
                <div className={styles.backdrop} onClick={(e) => e.stopPropagation()}></div>
              )}
            </td>
          )}
        </tr>
      );
    });

  const tableSkeleton = Array(5)
    .fill(0)
    .map((_, i) => (
      <tr key={i} className={styles.tableDataRow}>
        <td>
          <div className={styles.memberBlock}>
            <div className={styles.personBlock}>
              <Skeleton className={styles.personAvatar} />
              <div className={styles.personInfoBlock}>
                <Skeleton className={styles.personNameSkeleton} />
                <Skeleton className={styles.positionSkeleton} />
              </div>
            </div>
          </div>
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
      </tr>
    ));

  return (
    <Table
      sortInfo={sortInfo}
      isDataLoading={isDataLoading}
      onSortClick={onSortClick}
      tableHeaders={tableHeaders}
      skeleton={tableSkeleton}
      customStyles={{ tableHeaderRow: styles.tableHeaderRow, tableDataRow: styles.tableDataRow }}
    >
      {tableContent}
      {selectedLastRescheduleInfo && (
        <RescheduledEvalInfoModal
          isEop={selectedLastRescheduleInfo.isEop}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          lastRescheduleInfo={selectedLastRescheduleInfo.info}
        />
      )}
    </Table>
  );
};

export const MyTeamEvalReportTable = memo(MyTeamEvalReportTableComponent);
