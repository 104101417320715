import { FC, useEffect } from 'react';
import { useStyles } from './FeedbackUserSelector.styles';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { DataState } from '@shared/enums/DataState';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useSearchFunctions } from '@shared/helpers/useSearchFunctions';
import { loadActiveUsersList } from '@modules/App/redux/users/actions';
import { resetSearchResult } from '@modules/App/redux/users';
import { selectUsersSearchData } from '@modules/App/redux/users/selectors';
import { PeerSearchOption } from '@modules/EvaluationModule/pages/EvalSettingsPage/components/PeerSearch/components/PeerSearchOption';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { feedbackSelectorCustomStyles } from '@styles/FeedbackSelectorCustomStyles';
import { selectedManagerSelector, selectedUserSelector } from '@modules/App/redux/feedback/selectors';
import { setSelectedManager, setSelectedUser } from '@modules/App/redux/feedback';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { ReactComponent as ArrowDown } from '@assets/icons/ArrowDown.svg';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { mapUserToSharedUser } from '@shared/helpers/mapUserToSharedUser';

interface Props {
  placeholder: string;
  isManager?: boolean;
  isEM?: boolean;
  teamMemberWithInccorectReportTo?: UserInfo;
}

export const FeedbackUserSelector: FC<Props> = ({ placeholder, isManager, isEM, teamMemberWithInccorectReportTo }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { inputValue, handleInputChange } = useSearchFunctions(loadActiveUsersList, resetSearchResult);
  const searchData = useSelector(selectUsersSearchData);
  const selectedUser = useSelector(selectedUserSelector);
  const selectedManager = useSelector(selectedManagerSelector);
  const handleSelectUser = (user: UserInfo | null) => {
    dispatch(setSelectedUser(user));
  };
  const handleSelectManager = (user: ReturnType<typeof userSelector>) => {
    dispatch(setSelectedManager(user));
  };
  const selectedUserFromStore = useSelector(userSelector);

  useEffect(() => {
    const user =
      teamMemberWithInccorectReportTo ?? (selectedUserFromStore ? mapUserToSharedUser(selectedUserFromStore) : null);
    if (user) {
      handleSelectUser(user);
    }
    return () => {
      handleSelectUser(null);
      handleSelectManager(null);
    };
  }, [teamMemberWithInccorectReportTo, selectedUserFromStore]);

  const options = searchData.data
    ? searchData.data.filter((user) =>
        isManager && !isEM
          ? user.id !== selectedUser?.id && user.id !== selectedUser?.primaryReportsTo?.id
          : isEM
          ? user.id !== selectedUser?.id && user.id !== selectedUser?.engineeringManager?.id
          : user.id !== selectedManager?.id
      )
    : [];

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className={styles.dropdownIcon}>
          <ArrowDown />
        </div>
      </components.DropdownIndicator>
    );
  };

  return (
    <div className={styles.selectBlock}>
      <div className={styles.searchIcon}>
        <SearchRoundedIcon />
      </div>

      <Select
        styles={feedbackSelectorCustomStyles}
        placeholder={placeholder}
        components={{ Option: PeerSearchOption, DropdownIndicator }}
        onChange={(value) =>
          isManager
            ? handleSelectManager(value as ReturnType<typeof userSelector>)
            : handleSelectUser(value as UserInfo)
        }
        isLoading={searchData.state === DataState.Pending}
        controlShouldRenderValue={false}
        onInputChange={handleInputChange}
        noOptionsMessage={() => (inputValue === '' ? 'Start typing to search' : 'No results')}
        options={options}
        filterOption={null}
      />
      {!isManager && selectedUser && (
        <div className={styles.correctReportBlock}>
          <UserReporterBlock user={selectedUser} isDetailInfo />
        </div>
      )}
      {isManager && selectedManager && (
        <div className={styles.correctReportBlock}>
          <UserReporterBlock user={selectedManager} isDetailInfo />
        </div>
      )}
    </div>
  );
};
