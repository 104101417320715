import React, { FC, memo, useCallback } from 'react';
import { User } from '@shared/interfaces/user';
import { useStyles } from './SidebarContent.styles';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { ReactComponent as RockyLogoSign } from '@assets/icons/RockyLogoSign.svg';
import { ReactComponent as RockyLogoText } from '@assets/icons/RockyLogoText.svg';
import { ReactComponent as ProductLogoMobile } from '@assets/icons/ProductLogoMobile.svg';
import { ReactComponent as LogoSmall } from '@assets/icons/logo-small.svg';
import { MenuBurger } from '../MenuBurger';
import { SidebarContentItems } from './../SidebarContentItems';
import classNames from 'classnames';
import { SearchButton } from '@shared/components/SearchButton';
import { useDispatch, useSelector } from 'react-redux';
import { setIsGlobalSearchOverlayOpen } from '@modules/App/redux/users';
import { Link } from 'react-router-dom';
import { paths } from '@shared/enums/paths';
import { selectIsGlobalUsersSearchOpened } from '@modules/App/redux/users/selectors';
import { CrossLinksMenu } from '@product-studio/rocky-components';
import { Stack } from '@mui/material';
import { BREAKPOINTS } from '@styles/breakpoints';
import { CurrentUserMenu } from '@shared/components/CurrentUserMenu';
import { REFER_A_FRIEND_LINK } from '@shared/consts/constants';

interface Props {
  currentUser: User;
  setIsTabletMenuOpen: (bool: boolean | ((state: boolean) => boolean)) => void;
  isTabletMenuOpen: boolean;
}

const SidebarContentComponent: FC<Props> = ({ currentUser, isTabletMenuOpen = false, setIsTabletMenuOpen }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const isGlobalSearchOverlayOpen = useSelector(selectIsGlobalUsersSearchOpened);

  const handleGlobalSearchOverlayOpen = useCallback(() => {
    setIsTabletMenuOpen(false);
    dispatch(setIsGlobalSearchOverlayOpen(true));
  }, [isGlobalSearchOverlayOpen]);

  const crossLinksMenuElement = (
    <CrossLinksMenu
      referAFriendLink={REFER_A_FRIEND_LINK}
      user={{
        name: currentUser.name,
        imgSrc: currentUser.imageUrl ?? '',
        position: currentUser.position,
      }}
    />
  );

  return (
    <>
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{
          padding: '18px 10px 10px 20px',
        }}
      >
        <Link to={paths.root}>
          <div className={styles.logoContainer}>
            <Stack
              flexDirection="row"
              alignItems="center"
              className={classNames(styles.logo, { [styles.shown]: isTabletMenuOpen })}
              onClick={() => setIsTabletMenuOpen(false)}
            >
              <RockyLogoSign />
              <RockyLogoText />
            </Stack>

            <div className={classNames(styles.logoSmall, { [styles.hidden]: isTabletMenuOpen })}>
              <LogoSmall className={styles.smallLogo} />
            </div>
          </div>
        </Link>
        <Stack
          ml="auto"
          sx={{
            [BREAKPOINTS.TABLET]: {
              display: 'none',
            },
          }}
        >
          {crossLinksMenuElement}
        </Stack>
      </Stack>
      <div
        className={classNames(styles.unfoldMenu, { [styles.collapseMenu]: isTabletMenuOpen })}
        onClick={() => setIsTabletMenuOpen(!isTabletMenuOpen)}
      >
        <MenuRoundedIcon />
      </div>
      <div className={styles.searchButton}>
        <SearchButton isTabletMenuOpen={isTabletMenuOpen} onClick={handleGlobalSearchOverlayOpen} />
      </div>
      <div className={styles.navContianer}>
        <SidebarContentItems
          isTabletMenuOpen={isTabletMenuOpen}
          currentUser={currentUser}
          setIsTabletMenuOpen={setIsTabletMenuOpen}
        />
      </div>
      <Stack
        flexDirection="row"
        alignItems="center"
        width="100%"
        sx={{
          display: 'none',

          [BREAKPOINTS.MOBILE]: {
            display: 'flex',
          },
        }}
      >
        <Stack
          component={Link}
          to={paths.root}
          sx={{
            margin: '0 auto',
          }}
        >
          <ProductLogoMobile />
        </Stack>
        <Stack>{crossLinksMenuElement}</Stack>
      </Stack>
      <div
        onClick={() => setIsTabletMenuOpen(false)}
        className={classNames(styles.currentUserMenu, isTabletMenuOpen ? styles.borderBottomShown : '')}
      >
        <CurrentUserMenu isTabletMenuOpen={isTabletMenuOpen} user={currentUser}></CurrentUserMenu>
      </div>
      <div id="header-content-holder" className={styles.contentHolder}></div>
      <div className={styles.menuBurgerContainer}>
        <MenuBurger>
          <SidebarContentItems
            isTabletMenuOpen={isTabletMenuOpen}
            currentUser={currentUser}
            setIsTabletMenuOpen={setIsTabletMenuOpen}
          />
        </MenuBurger>
      </div>
    </>
  );
};

export const SidebarContent = memo(SidebarContentComponent);
