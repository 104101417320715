import httpClient from '@shared/api/httpClient';
import httpNodeClient from '@shared/api/httpNodeClient';
import { mapUserData } from '@shared/interfaces/helpers/mapUserData';
import { EvaluationOptionsData } from '../interfaces/EvaluationOptionsData';
import { PeerFormData } from '../interfaces/PeerEvalData';
import { getEvalMarksPageData, mapEvaluationOptionsData } from './helpers/evaluationResponse';
import { PeerEvalResponseData, ReviewPeerFeedback } from './types';
import { AxiosResponse } from 'axios';
import { SubmitEvalPayloadPeer } from '../interfaces/SubmitEvalPayload';
import { apiUrlNode } from '@configs/environment';
import { getPeerFeedbackPermissions } from './helpers/getEvaluationPermissions';

export const fetchPeerEvalData = ({
  evalId,
  feedbackId,
}: {
  evalId: string;
  feedbackId: string;
}): Promise<PeerFormData> =>
  httpClient.get<{ data: PeerEvalResponseData }>(`evaluations/${evalId}/peer-feedbacks/${feedbackId}/`).then((resp) => {
    const {
      evaluatee,
      evaluator,
      evalutionDate,
      reviewer_notes,
      status,
      evaluation,
      is_anonymous,
      evaluation_history_id,
    } = resp.data.data;

    return {
      id: feedbackId,
      evaluationHistoryId: evaluation_history_id,
      evaluatee: mapUserData(evaluatee),
      evaluator: { ...evaluator, name: evaluator.full_name },
      evalutionDate,
      reviwerNotes: reviewer_notes,
      status: status,
      evaluation: getEvalMarksPageData(evaluation),
      isAnonymous: is_anonymous,
    };
  });

export const postPeerEvalData = ({ evaluation, evalId, feedbackId, isAnonymous }: SubmitEvalPayloadPeer): void => {
  const mappedEvaluation = mapEvaluationOptionsData(evaluation);

  httpClient.post<EvaluationOptionsData>(`/evaluations/${evalId}/peer-feedbacks/${feedbackId}/`, {
    evaluation: mappedEvaluation,
    is_anonymous: isAnonymous,
  });
};

export const reviewPeerFeedback = ({
  status,
  reviewerNotes,
  evalId,
  feedbackId,
}: ReviewPeerFeedback): Promise<AxiosResponse> => {
  const mappedPayload = {
    status,
    reviewer_notes: reviewerNotes,
  };

  return httpClient.patch<{ data: PeerEvalResponseData }>(
    `/evaluations/${evalId}/peer-feedbacks/${feedbackId}/`,
    mappedPayload
  );
};

export const fetchPeerFeedbackPermissions = (peerFeedbackId: string) =>
  httpNodeClient.get(`${apiUrlNode}/peer-feedbacks/${peerFeedbackId}/permissions`).then(({ data }) => {
    return getPeerFeedbackPermissions(data);
  });
